import { useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Navigation from './components/organisms/Navigation'
import { useDatabase } from './hooks'
import { AppContext } from './context'
import Home from './components/pages/Home'

const App = () => {
  /// temp fix
  const isSloganLoaded = true
  const slogan = {
    header: {
      byLine: '—ux.ui',
      paragraph:
        "Hi, my name is René Krewinkel - I am a very experienced (30+ years) digital bohemian. I'm currently working as a front-end architect, graphic/ux/ui designer, consultant and teacher in software development."
    },
    footer: {}
  }
  //const [slogan, isSloganLoaded] = useDatabase('slogan')

  const [projects, isProjectsLoaded] = useDatabase('project')

  const renderContent = () => {
    if (isProjectsLoaded && isSloganLoaded) {
      return (
        <Routes>
          <Route path='/' element={<Navigation />}>
            <Route index element={<Home projects={projects} />} />
            {/*<Route path='/:slug' element={<Detail data={content} />} />*/}
          </Route>
        </Routes>
      )
    }
  }
  return (
    <HelmetProvider>
      <AppContext.Provider value={{ slogan, projects }}>
        <BrowserRouter>{renderContent()}</BrowserRouter>
      </AppContext.Provider>
    </HelmetProvider>
  )
}

export default App
