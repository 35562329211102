import PropTypes from 'prop-types'

const ProjectIndicator = ({ testID = null, text }) => {
  const indicators = text.split('')

  return (
    <div data-testid={testID} className={`ProjectIndicator`}>
      {indicators.map((item, index) => {
        return (
          <div className={'indicator'} key={`indicator-${index}`}>
            {item}
          </div>
        )
      })}
    </div>
  )
}

ProjectIndicator.propTypes = {
  testID: PropTypes.string,
  text: PropTypes.string
}

export default ProjectIndicator
