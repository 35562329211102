import PropTypes from 'prop-types'
import Label from '../../atoms/Label'
import { useDateConversion } from '../../../hooks'
import TextParagraph from '../../atoms/TextParagraph'

const ProjectFooter = ({ testID = '', data = {} }) => {
  const s = useDateConversion(data.startDate)
  const e = useDateConversion(data.endDate)

  return (
    <div className={'PF'}>
      <table
        data-testid={testID}
        cellspacing={0}
        cellpadding={0}
        style={{ width: '100%' }}
        className={`ProjectFooter`}
      >
        <tr>
          <td className={`project-footer-title`} style={{ width: '40%' }}>
            <Label shade='light' type='bright' size='xl' text={data.title} />
          </td>
          <td
            rowspan={2}
            valign={'top'}
            className={`project-footer-title shaded`}
          >
            <TextParagraph content={data.description} />
          </td>
        </tr>
        <tr>
          <td valign={'top'}>
            <table style={{ width: '100%' }} cellspacing={0} cellpadding={0}>
              <tr>
                <td className={`project-footer-title`}>
                  <Label shade='light' type='bright' size='s' text={'Client'} />
                </td>
                <td className={`project-footer-title hide`}>
                  <Label
                    shade='light'
                    type='muted'
                    size='s'
                    text={data.client}
                  />
                </td>
              </tr>

              <tr>
                <td className={`project-footer-title`} style={{ width: '30%' }}>
                  <Label shade='light' type='bright' size='s' text={'URL'} />
                </td>
                <td className={`project-footer-title`}>
                  <a href={data.url} target='_blank'>
                    <Label
                      shade='light'
                      type='muted'
                      size='s'
                      text={data.url}
                    />
                  </a>
                </td>
              </tr>

              <tr>
                <td className={`project-footer-title`} style={{ width: '30%' }}>
                  <Label
                    shade='light'
                    type='bright'
                    size='s'
                    text={'Start date'}
                  />
                </td>
                <td className={`project-footer-title`}>
                  <Label shade='light' type='muted' size='s' text={s} />
                </td>
              </tr>

              <tr>
                <td className={`project-footer-title`} style={{ width: '30%' }}>
                  <Label
                    shade='light'
                    type='bright'
                    size='s'
                    text={'End date'}
                  />
                </td>
                <td className={`project-footer-title`}>
                  <Label shade='light' type='muted' size='s' text={e} />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  )
}

ProjectFooter.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default ProjectFooter
