import PropTypes from 'prop-types'

const LogoType = ({ testID = '', size = 'large', type = 'logo', text }) => {
  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      data-size={size ?? 'large'}
      className={`LogoType`}
    >
      {text}
    </div>
  )
}

const exceptionClasses = ['logo', 'slogan']

const sizeClasses = ['small', 'large']

LogoType.propTypes = {
  testID: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(sizeClasses),
  type: PropTypes.oneOf(exceptionClasses)
}

export default LogoType
