// import { initializeApp } from 'firebase/app'
// import { getFirestore } from 'firebase/firestore'
//
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   //  projectId: "krewinkel-nl",
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// }

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCUEQ2SpynZR7sBmfa362vNytNqPdDBOEc',
  authDomain: 'krewinkel-site.firebaseapp.com',
  projectId: 'krewinkel-site',
  storageBucket: 'krewinkel-site.appspot.com',
  messagingSenderId: '1080420759540',
  appId: '1:1080420759540:web:1f4184d6d282b9ae282ba1',
  measurementId: 'G-1Y23HZ7GMH'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
//const analytics = getAnalytics(app);

//const app = initializeApp(firebaseConfig)
const database = getFirestore(app)

export { app, database }
