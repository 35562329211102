import PropTypes from 'prop-types'
import LogoType from '../../atoms/LogoType'
import ContactItem from '../../atoms/ContactItem'
import defaults from '../../../config/defaults'

const FooterBar = ({ testID = '', data }) => {
  return (
    <div data-testid={testID} className={`FooterBar`}>
      <LogoType type={'logo'} text={defaults.logo} size={'small'} />
      <ContactItem type={`email`} value={defaults.email} />
      <ContactItem type={`link`} value={defaults.linkedin} />
      <ContactItem type={`link`} value={defaults.blog} />
      <ContactItem type={`link`} value={defaults.npm} />
      <ContactItem type={`link`} value={defaults.github} />
    </div>
  )
}

FooterBar.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default FooterBar
