import PropTypes from 'prop-types'

import ProjectList from '../../organisms/ProjectList'

const Home = ({ testID = '', projects }) => {
  return (
    <div data-testid={testID} className={`Home`}>
      <ProjectList projects={projects} />
    </div>
  )
}

Home.propTypes = {
  testID: PropTypes.string,
  projects: PropTypes.array
}

export default Home
