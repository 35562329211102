import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ContactItem = ({ testID = '', type = '', value }) => {
  const url = typeof value === 'object' ? value.url : value
  const label = typeof value === 'object' ? value.label : value
  const link = `${type === 'email' ? 'mailto:' : ''}${url}`

  return (
    <div data-testid={testID} className={`ContactItem`}>
      <a href={link} target='_blank' rel='noreferrer'>
        {label}
      </a>
    </div>
  )
}

const exceptionClasses = ['email', 'link', 'phone']

ContactItem.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses),
  value: PropTypes.any
}

export default ContactItem
