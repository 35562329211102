const pText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "
const slogan = '—ux/ui'

const project = {
  index: 0,
  title: 'demo project',
  client: 'demo client',
  data: {}
}

const defaults = {
  logo: '—kr',
  email: 'rene@krewinkel.nl',
  linkedin: {
    url: 'https://www.linkedin.com/in/renekrewinkel/',
    label: 'LinkedIn'
  },
  blog: { url: 'https://blog.krewinkel.studio', label: 'Blog' },
  npm: { url: 'https://www.npmjs.com/~renekrewinkel', label: 'NPM' },
  github: { url: 'https://github.com/ReneKrewinkel', label: 'GitHub' },
  slogan: slogan,
  label: 'label',
  projectIndicator: '01',
  paragraphText: pText,
  headerBar: { byLine: slogan, paragraph: pText },
  footerBar: { paragraph: pText },
  project: project
}

export default defaults
