import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const TextParagraph = ({ testID, content = '' }) => {
  return (
    <div data-testid={testID} className={`TextParagraph`}>
      <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
    </div>
  )
}

TextParagraph.propTypes = {
  testID: PropTypes.string,
  content: PropTypes.string
}

export default TextParagraph
