import PropTypes from 'prop-types'
import HeaderBar from '../../molecules/HeaderBar'

const Header = ({ testID = '', data }) => {
  return (
    <div data-testid={testID} className={`Header`}>
      <HeaderBar data={data} />
    </div>
  )
}

Header.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default Header
