import PropTypes from 'prop-types'
import GridItem from '../GridItem'

const Grid = ({ testID = '', type = '', data }) => {
  return (
    <div data-testid={testID} data-object-type={`${type}`} className={`Grid`}>
      {data.content?.map((item, index) => {
        return (
          <div key={`grid-list-${index}`} className={`item item-${index}`}>
            <GridItem data={item} />
          </div>
        )
      })}
    </div>
  )
}

const exceptionClasses = ['01', '02']

Grid.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses),
  data: PropTypes.object
}

export default Grid
