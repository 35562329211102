import PropTypes from 'prop-types'

const Label = ({
  testID = '',
  size = 's',
  shade = 'dark',
  type = 'bright',
  text = ''
}) => {
  return (
    <div
      data-testid={testID}
      data-object-shade={shade ?? ''}
      data-object-size={size ?? ''}
      data-object-type={type ?? ''}
      className={`Label`}
    >
      {text}
    </div>
  )
}

const typeClasses = ['bright', 'muted']
const sizeClasses = ['xs', 's', 'm', 'l', 'xl']
const colorClasses = ['dark', 'light']

Label.propTypes = {
  testID: PropTypes.string,
  size: PropTypes.oneOf(sizeClasses),
  shade: PropTypes.oneOf(colorClasses),
  type: PropTypes.oneOf(typeClasses),
  text: PropTypes.string
}

export default Label
