import PropTypes from 'prop-types'
import Project from '../../molecules/Project'

const ProjectList = ({ testID = '', projects }) => {
  return (
    <div data-testid={testID} className={`ProjectList`}>
      {projects.map((item, index) => {
        return <Project key={`project-${index}`} index={index} data={item} />
      })}
    </div>
  )
}

ProjectList.propTypes = {
  testID: PropTypes.string
}

export default ProjectList
