import { useEffect, useState } from 'react'

const useDateConversion = (d) => {
  let [result, setResult] = useState('')

  useEffect(() => {
    if (d && d !== '') {
      const dt = new Date(d.seconds * 1000)
      const m = ('0' + (dt.getMonth() + 1)).slice(-2)
      setResult(`${m}-${dt.getFullYear()}`)
    }
  }, [d])

  return result
}

export default useDateConversion
