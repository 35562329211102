import PropTypes from 'prop-types'

const ParagraphText = ({
  testID = '',
  type = 'regular',
  text = '',
  align = 'left'
}) => {
  return (
    <p
      data-testid={testID}
      data-object-type={type ?? ''}
      data-align={align ?? 'left'}
      className={`ParagraphText`}
    >
      {text}
    </p>
  )
}

const exceptionClasses = ['regular', 'project']

const alignException = ['left', 'right']

ParagraphText.propTypes = {
  testID: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses),
  align: PropTypes.oneOf(alignException)
}

export default ParagraphText
