import { useEffect, useState } from 'react'
const useSlug = (slug, data) => {
  const [result, setResult] = useState(
    data.filter((item) => item.type === 'header')
  )
  let location = slug.split('/')

  useEffect(() => {
    if (location[1] !== '') {
      setResult(data.filter((item) => item.slug === `/${location[1]}`)[0])
    }
  }, [])

  return result
}

export default useSlug
