import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextParagraph from '../../atoms/TextParagraph'

const GridItem = ({ testID = '', data }) => {
  const type = null
  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`GridItem`}
    >
      {data.position === 'right' && (
        <div className={`item-text`}>
          <TextParagraph content={data.paragraph} />
        </div>
      )}
      <div className={`item-image`}>
        <img src={data.image} alt={data.title} />
      </div>
      {data.position === 'left' && (
        <div className={`item-text`}>
          <TextParagraph content={data.paragraph} />
        </div>
      )}
    </div>
  )
}

GridItem.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default GridItem
