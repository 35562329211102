import PropTypes from 'prop-types'
import LogoType from '../../atoms/LogoType'
import ParagraphText from '../../atoms/ParagraphText'
import defaults from '../../../config/defaults'

const HeaderBar = ({ testID = '', data }) => {
  const { byLine, paragraph } = data

  return (
    <div data-testid={testID} className={`HeaderBar`}>
      <div className={`topbar`}>
        <LogoType type={'logo'} text={defaults.logo} size={'large'} />
        <LogoType type={'slogan'} text={byLine} size={'large'} />
      </div>

      <div className={`contentbar`}>
        <ParagraphText text={paragraph} type={`regular`} align={`right`} />
      </div>
    </div>
  )
}

HeaderBar.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default HeaderBar
