import PropTypes from 'prop-types'
import FooterBar from '../../molecules/FooterBar'
const Footer = ({ testID = '', data }) => {
  return (
    <div data-testid={testID} className={`Footer`}>
      <FooterBar data={data} />
    </div>
  )
}

Footer.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default Footer
