import { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useLocation } from 'react-router-dom'
import { config } from '../../../config'
import { AppContext } from '../../../context'
import { useSlug } from '../../../hooks'
import Helmet from '../../molecules/Helmet'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'

const Navigation = ({ testID = '', data = [] }) => {
  const { header, footer } = useContext(AppContext).slogan
  const location = useLocation().pathname //.pathname.split('/')
  const page = useSlug(location, data)

  return (
    <div className={`Navigation`}>
      <Helmet
        title={page?.title}
        description={`${config.siteName} :: ${page?.contentShort}`}
      />
      <Header data={header} />
      <Outlet />
      <Footer data={footer} />
    </div>
  )
}

Navigation.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.array
}

export default Navigation
