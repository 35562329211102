import { useState } from 'react'
import PropTypes from 'prop-types'
import ProjectIndicator from '../../atoms/ProjectIndicator'
import ProjectDescription from '../ProjectDescription'
import ProjectFooter from '../ProjectFooter'

import 'react-slidedown/lib/slidedown.css'
import { SlideDown } from 'react-slidedown'
import Grid from '../Grid'

const Project = ({ testID = '', data, index }) => {
  const [open, setOpen] = useState(false)
  const idx = index < 9 ? `0${index + 1}` : (index + 1).toString()

  return (
    <div data-testid={testID} className={`Project ${open ? 'open' : ''}`}>
      <div className={`project-info-bar`} onClick={() => setOpen(!open)}>
        <ProjectIndicator text={idx} />
        <ProjectDescription data={data} />
      </div>
      <SlideDown>
        {open ? (
          <>
            <Grid type={data.gridType} data={data} />
            <ProjectFooter data={data} />
          </>
        ) : null}
      </SlideDown>
    </div>
  )
}

Project.propTypes = {
  testID: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object
}

export default Project
