import PropTypes from 'prop-types'
import Label from '../../atoms/Label'
import { useDateConversion } from '../../../hooks'

const ProjectDescription = ({ testID = '', data = {} }) => {
  const startDate = useDateConversion(data.startDate)
  const endDate = useDateConversion(data.endDate)

  const renderDate = () => {
    const s = startDate ? startDate : ''
    const e = endDate ? ` - ${endDate}` : ''
    const dt = `${s}${e}`

    return <Label type={'muted'} shade={'light'} size={'xs'} text={dt} />
  }

  return (
    <div data-testid={testID} className={`ProjectDescription`}>
      <Label type={'bright'} shade={'light'} size={'m'} text={data.title} />

      <Label type={'muted'} shade={'light'} size={'s'} text={data.client} />

      {renderDate()}
    </div>
  )
}

ProjectDescription.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.object
}

export default ProjectDescription
